/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Login from "./views/adminauth/Login.jsx";
//import Forgotpassword from "./views/adminauth/Forgotpassword.jsx";
import Register from "./views/adminauth/Register.jsx";

var authRoute = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },

  {
    path: "/register",
    name: "Register",
    icon: "ni ni-key-25 text-info",
    component: Register,
    layout: "/auth"
  },

  /*{
    path: "/password/reset",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Forgotpassword,
    layout: "/auth"
  }*/
 
];
export default authRoute;
