import Index from "views/Index.jsx";
import Products from "views/products/Index.jsx";
import Requests from "./views/requests/Index.jsx";
import Settings from "views/settings/Index.jsx";
import Blogs from "views/blogs/Index.jsx";
import Image from "./views/requests/image.js";
var routes = [
  {
    path: "/home",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/products",
    name: "product",
    icon: "ni ni-archive-2 text-green",
    component: Products,
    layout: "/admin"
  },
  
  {
    path: "/requests",
    name: "Requests",
    icon: "fa fa-download text-blue",
    component: Requests,
    layout: "/admin"
  },
  {
    path: "/blog",
    name: "Blog",
    icon: "ni ni-box-2 text-yellow",
    component: Blogs,
    layout: "/admin"
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-settings text-red",
    component: Settings,
    layout: "/admin"
  },
   
];
export default routes;
