
import React, { Component } from "react";
import { FormGroup, CardHeader, Media, Input, Modal, ButtonGroup } from "reactstrap";

class Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images:[],
            rotation:0,
        }

        this.rotate = this.rotate.bind(this);
    }


    rotate = (index) => () => {

        let newRotation = this.state.images.filter((image, key) => key == index) + 90;
        if (newRotation >= 360) {
            newRotation = - 360;
        }
        this.setState({
            rotation: newRotation,
        })
    }

    imageHandler = (e) => {
        this.setState({ images: [...this.state.images, ...e.target.files] })
      }


    render() {
        const { rotation, images } = this.state;

        return (
            <>
                {this.state.images.map((image, key) => (

                    <>
                        
                        <div>
                            <img style={{ transform: `rotate(${rotation}deg)` }} src={image.name} width="400" /><input onClick={this.rotate} type="button" value="right" />

                        </div>
                    </>
                ))}
                <div className="file-field" style={{margin:500}}>
                    <div className="d-flex justify-content-xl-center ms-xl-3">
                    <div className="d-flex">


                       
                        <div className="d-md-block text-start">
                        <span className="icon icon-md">
                            {/* <FontAwesomeIcon icon={faPaperclip} className="me-3" /> */}
                        </span>
                        <div className="fw-normal text-dark mb-1">Choose Images</div>
                        <div className="text-gray small"> JPG, GIF or PNG. Max size of 2MB</div>
                        </div>
                    </div>

                    </div>
                </div>
            </>
        )

    }
}
export default Image;

