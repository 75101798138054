import React from "react";
import { Row, Col, Button, Modal, FormGroup, Input,FormText } from "reactstrap";
import { toast } from "react-toastify";

import { addSlider } from "services/sliderService";
import SpinDiv from "components/SpinDiv.jsx";
import axios from "axios";
import settings from "../../../services/settings";
import { authHeader } from "../../../services/authHeader";
import { authService } from "../../../services/authService";

class AddSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      search: "",
      validation: {},
      images: [],
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { images, validation } = this.state;
    await this.setState({
      validation: {
        ...validation,
        images: images !== "",
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveSlider();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  validationRules = (field) => {
    if (field === "images") {
      return "Image is required";
    }
  };

  saveSlider = () => {
    this.setState({ loading: true });
    const {
     
      images
    } = this.state;
    if (this.state.images) {
      let data = new FormData();
      let files = this.state.images;
      for (let i = 0; i < files.length; i++) {
        data.append(`images[${i}]`, files[i])
    }
      //data.append("images", this.state.images);
      return axios
        .post(
          `${settings.API_URL}addslider`,
          data,
          {
            headers: authHeader(),
          },
          authService.handleResponse
        )
        .then((res) => {
          console.log(res.data);
          this.setState({ loading: false });
          this.props.saved();
          this.props.toggle();
          this.showToast("Slider Created");
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            errorMessage: err,
            show: true,
          });
          if (this.state.errorMessage) {
            this.showToast("Invalid Product Name / File Type or size");
          }
          this.setState({ loading: false });
        });
    }
  };


  showToast = (msg) => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };
  imageHandler = (e) => {
    this.setState({ images: [...this.state.images, ...e.target.files] })
  }

  render() {
    const { add, toggle } = this.props;
    const { loading, validation } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={add}
          toggle={() => !loading && toggle}
          style={{ maxWidth: 500 }}
        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <h3 className="modal-title" id="exampleModalLabel">
              Add Slider
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{ border: "1px solid #eee" }}>
            <Row>
            <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-file"
                    style={{ color: validation.images === false ? "red" : null }}
                  >
                    Upload Product Images
                  </label>
                  <input
                    style={{ height: 41 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-file"
                    onChange={this.imageHandler}
                    type="file" multiple
                  />
                  <FormText color="muted">
                    you can only upload jpg,jpeg and png type
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            
          </div>
          <div className="modal-footer" style={{ padding: "1rem" }}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={loading}
              onClick={toggle}
            >
              Close
            </Button>
            <Button
              color="success"
              type="button"
              disabled={loading}
              size="sm"
              onClick={this.onSave}
              style={{
                backgroundColor: "#EC3237",
                borderColor: "#EC3237",
                color: "#fff",
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddSlider;
