import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import { toast } from 'react-toastify';
import "views/index.css";
import {getRequests }  from 'services/requestService';
//import {getUser} from 'services/authService'
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";
import { Pagination } from 'antd';

class Requests extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
  	page: 1,
  	rows: 10,
  	total: 0,
    search: '',
    users: [],
    requests: []
  };

  componentDidMount() {
    this.getRequests();
  }

  componentWillMount() {
  }

  

  getRequests = () => {
    const {user, page, rows, search} = this.state;
    this.setState({loading: true});
    getRequests({user, page, rows, search})
      .then((res) => {console.log(res)
        this.setState({
          requests: res.requests.data,
          users: res.users,
          page: res.requests.current_page,
          total: res.requests.total,
          loading: false,
        })
      },
      error => {
        this.setState({loading: false});
      });
  }



  onChange = (e, state) => {
    this.setState({[state]: e})
  }
 
  
  onPage = async (page) => {
    const {search} = this.state;
    await this.setState({page});
    if(search===''){
      await this.getRequests();
    } else {
      await this.search();
    }
  }

  onSearch = () => {

  }

  

  

  
  showToast = msg => {
    toast.error(<div style={{padding:25}}>{msg}</div>);
  }


  render() {
    const {requests, users,  total, page, rows, search, loading} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#3E70D7'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap'}}>
                    <div>
                      <h3 className="mb-0">requests <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    <div className="mobile-flex">
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getRequests();}}} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getRequests}>
                        <i className="fa fa-search" />
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr >
                      <th scope="col">Product Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Customer Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Product Id</th>
                      <th scope="col">Date</th>
                      
                     
                    </tr>
                  </thead>
                  <tbody>
                    {requests.map((request, key) => {
                      return (
                        <tr key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/requests/'+request.id)}}>
                          {request.productname ? <td>{request.productname.length > 50 ? 
                          request.productname.substr(0, 20)+"....":request.productname}</td> : ""}
                          
                          <td>{request.price}</td>
                          <td>{request.name}</td>
                          <td>{request.phone}</td>
                          <td>{request.email}</td>
                          <td>{request.product_id}</td>
                          <td>{request.created_at}</td>
                        </tr>
                        
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&requests.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Requests found
                </div>}
               
                {requests.length>0&&<CardFooter className={`py-4 ${loading?'load-opacity':''}`}>
                <Pagination
                  total={total}
                  showTotal={total => `Total ${total} items`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                  showSizeChanger
                  showQuickJumper
                />
                </CardFooter>}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Requests;
