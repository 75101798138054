import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest } from "services/requestService";
import { getUser } from "services/authService";
import SpinDiv from "components/SpinDiv.jsx";
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

class Download extends React.Component {
  constructor(props) {
    super(props);
    const { location: state } = props;
    this.state = {
      loading: false,
      edit: false,
      change: false,
      download: state && state.download ? state.download : null,
      validation: {},
      id: props.match.params.id,
      users: [],
    };
  }

  componentDidMount() {
    this.getRequest();
  }

  formatArea = val => <div>{val} ft<sup>3</sup></div>

  getRequest = () => {
    const { id } = this.state;
    this.setState({ loading: true });
    getRequest(id).then(
      (res) => {
        console.log(res);
        this.setState({
          loading: false,
          download: { ...res.request },
         
          users: res.users,
          //.map(p=>({label:p.name, value:p.id})),
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  toggleEdit = () => {
    const { initialDownload } = this.state;
    this.setState({ edit: !this.state.edit, download: { ...initialDownload } });
  };

  togglePassword = () => {
    this.setState({ change: !this.state.change });
  };

  

 

  render() {
    const {
      download,
      loading,
      validation,
      edit,
      
      initialDownload,
      saving,
      change,
      users,
    } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minWidth: 170,
        height: 46,
        borderColor: "hsl(0,0%,95%)",
        boxShadow: "1px 1px 1px rgba(50, 50, 50, 0.1)",
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      },
    };

    return (
      <>
        <div
          className="header pb-8 pt-5 pt-md-6"
          style={{ backgroundColor: "#3E70D7" }}
        >
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/requests"
                className="white-link"
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <i className=" ni ni-bold-left" style={{ marginRight: 5 }} />
                <span>Back to Requests</span>
              </Link>
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
           
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                {loading && <SpinDiv text={"Loading..."} />}
                {saving && <SpinDiv text={"Saving..."} />}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      {initialDownload && (
                        <h3 className="mb-0">
                          Owner: {initialDownload.user.name}
                        </h3>
                      )}
                    </Col>
                    {initialDownload &&
                    (getUser().admin ||
                      getUser().id === initialDownload.user_id) ? (
                      <Col className="text-right" xs="4">
                        {download && (
                          <Button
                            color={edit ? "secondary" : "success"}
                            onClick={this.toggleEdit}
                            size="sm"
                          >
                            {edit ? "Discard Changes" : "Edit"}
                          </Button>
                        )}
                      </Col>
                    ) : (
                      <Col className="text-right" xs="4">
                        {download && (
                          <Button
                            color="success"
                            onClick={this.addSubDownload}
                            size="sm"
                          >
                          
                          </Button>
                        )}
                      </Col>
                    )}
                  </Row>
                </CardHeader>
                {download && (
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-2">
                        Request Information
                      </h6>
                      <div>
                      <Row>
                      <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Phone: {download.phone}&nbsp;<br/>
                              </label>
                              <br/>
                             
                                <Button
                                  color="success"
                                
                                  size="sm"
                                >
                                   <a style={{color:"white"}}  target="_blank" href={"https://api.whatsapp.com/send?phone=234" + download.phone+"&text="+download.product_id} class="button">
                                   Message Requestor</a>
                                </Button>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            {download.product && (
                              <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                {download.product? 
                                  " Number Of Requests":
                                  "Quotation Request" }
                              </label>
                              <Input
                                value={download.product ? 
                                  download.product.request_count:
                                  "Quotation Request" }
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled                               
                              ></Input>
                            </FormGroup>
                            )}
                            
                          </Col>
                        </Row>
                        
                        
                      <Row>
                      <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Email
                              </label>
                              <Input
                                value={download.email}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled                               
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            {download.repair_option && (
                              <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                               Quotation Selection
                              </label>
                              <Input
                                value={download.repair_option }
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled                               
                              ></Input>
                            </FormGroup>
                            )}
                            
                          </Col>
                        </Row>
                        
                        <Row>
                      <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Requestor
                              </label>
                              <Input
                                value={download.name}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled                               
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Product Id
                              </label>
                              <Input
                                value={download.product_id}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled                               
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        
                        <Row>
                        <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Message
                              </label>
                              <Input
                                value={download.message}
                                type="textarea"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled                               
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                        <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pagecount"
                              >
                                Product Description
                              </label>
                              <SunEditor
                                setOptions={{
                                  height: 200,
                                  buttonList: buttonList.complex // Or Array of button list, eg. [['font', 'align'], ['image']]
                                  // Other option
                                }}
                                disable
                                setContents={download.description}
        
                              />
                             
                            </FormGroup>
                          </Col>
                        </Row>

                        
                        
                        
                      </div>
                      <hr style={{ marginBottom: 20, marginTop: 10 }} />
                      {edit && (
                        <div>
                          <Button
                            color="success"
                            type="button"
                            disabled={saving}
                            onClick={this.onSave}
                            style={{
                              backgroundColor: "#EC3237",
                              borderColor: "#EC3237",
                              color: "#fff",
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Download;
