import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  Input,
  Label,
  FormText,
} from "reactstrap";
import { toast } from "react-toastify";
import SunEditor, {buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from "axios";
import SpinDiv from "components/SpinDiv.jsx";
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { AiOutlineLogin } from "react-icons/ai";


class AddProduct extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      activeNav: 1,
      loading: false,
      search: "",
      categories: props.categories,
      users: props.users,
      validation: {},
      images:[],
      rotation:0,
      productname: "",
      price: "",
      discount: "",
      approve: true,
      category_id: "",
      video_link:"",
      description:""
    };
    this.rotate = this.rotate.bind(this);
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {
      productname,
      category_id,
      approve,
      description,
      video_link,
      price,
      discount,
      images,
      validation,
    } = this.state;
    await this.setState({
      validation: {
        ...validation,
        productname: productname !== "",
        category_id: category_id !== "",
        approve: approve !== "",
        video_link: video_link !== "",
        description: description !== "",
        discount: discount !== "",
        price: price !== "",
        images: images !== "",
      },
    });
    {console.log(Object.values(this.state.validation))}
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveProduct();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  validationRules = (field) => {
    if (field === "productname") {
      return "Product name is required";
    } else if (field === "category_id") {
      return "Category is required";
    } else if (field === "description") {
      return "Description is required";
    } else if (field === "price") {
      return "Price is required";
    } else if (field === "discount") {
      return "Discount is required";  
    } else if (field === "approve") {
      return "approve is required";
    } 
    else if (field === "price") {
      return "Price is required";
    }else if (field === "images") {
      return "Image is required";
    }
  };

  saveProduct = () => {
    this.setState({ loading: true });
    const {
      productname,
      category_id,
      approve,
      description,
      video_link,
      price,
      discount,
      images
    } = this.state;
    if (this.state.images) {
      let data = new FormData();
      if (this.state.approve === true) {
        this.setState({
          appr: 1,
        });
      } else {
        this.setState({
          appr: 0,
        });
      }
      let files = this.state.images;
      for (let i = 0; i < files.length; i++) {
        data.append(`images[${i}]`, files[i])
      }
      for (let i = 0; i < files.length; i++) {
       let new_rotation = files[i].rotation == undefined ? 0 : files[i].rotation;
        data.set(`rotations[${i}]`, new_rotation )
      }
      //data.append("images", this.state.images);
      data.set("productname", this.state.productname);
      data.set("category_id", this.state.category_id);
      data.set("discount", this.state.discount);
      data.set("description", this.state.description);
      data.set("approve", this.state.appr);
      data.set("video_link", this.state.video_link);
      data.set("price", this.state.price);

      return axios
        .post(
          `${settings.API_URL}addproduct`,
          data,
          {
            headers: authHeader(),
          },
          authService.handleResponse
        )
        .then((res) => {
          this.setState({ loading: false });
          this.props.saved();
          this.props.toggle();
          this.showToast("Product Created");
        })
        .catch((err) => {
          this.setState({
            errorMessage: err,
            show: true,
          });
          if (this.state.errorMessage) {
            console.log(this.state.errorMessage.productname);
            this.showToast("Invalid Product Name / File Type or size");
          }
          this.setState({ loading: false });
        });
    }
  };

  showToast = (msg) => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };
  handleChange=(content)=>{
    this.setState({description:content });
     //Get Content Inside Editor
  };
  imageHandler = (e) => {
    this.setState({ images: [...this.state.images, ...e.target.files] })
  }

  rotate = (image, index) => () => {
    const {images} = this.state;
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
        newRotation = - 360;
    }
    image.rotation = newRotation;
    images.splice(index, 1, image);
    this.setState({
        rotation: newRotation,
        rotation_index: index,
    })
  }


  render() {
    const { addProduct, toggle } = this.props;

    const {
      products,
      categories,
      approve,
      saving,
      loading,
      validation,
      images,
      rotation,
      rotation_index,
      category_id,
      description,
      video_link,
      price,
      discount
    } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    return (
      <>
        {console.log(this.state.approve)}
        <Modal
          className="modal-dialog-full"
          isOpen={addProduct}
          toggle={() => !saving && toggle}
          style={{ maxWidth: 700 }}
        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <h3 className="modal-title" id="exampleModalLabel">
              Add Product
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <div className="modal-body" style={{ border: "1px solid #eee" }}>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{ color: validation.productname === false ? "red" : null }}
                  >
                    <Required />
                    Add Product Name
                  </label>
                  <Input
                    style={{ height: 35 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-productname"
                    onChange={(e) => this.onChange(e.target.value, "productname")}
                    placeholder="Add Product"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-category_id"
                  >
                    Choose Category
                  </label>
                  <Input
                    value={category_id}
                    type="select"
                    bsSize="sm"
                    style={{
                      marginRight: 10,
                      width: "100%",
                      color: validation.category_id === false ? "red" : null,
                    }}
                    onChange={async (e) => {
                      await this.onChange(e.target.value, "category_id");
                    }}
                  >
                    <option disabled value="">
                      Select Category
                    </option>
                    {categories.map((p) => (
                      <option value={p} key={p}>
                        {p}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
             </Row> 
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-file"
                    style={{ color: validation.images === false ? "red" : null }}
                  >
                    Upload Product Images
                  </label>
                  <input
                    style={{ height: 41 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-file"
                    onChange={this.imageHandler}
                    type="file" multiple
                  />
                  <FormText color="muted">
                    you can only upload jpg,jpeg and png type
                  </FormText>
                </FormGroup>
                <Row>
                 
                  {this.state.images.map((image, key) => (
                 

                    <>
                       
                        <Col md={4}>
                            <img  style={ { transform: key == rotation_index ? `rotate(${rotation}deg` : 'rotate(0)deg', opacity:0.2 } } src={URL.createObjectURL(image)} width="100" />
                            <div style={{position: 'absolute', top: '50%',width: '100%',textAlign: 'center',fontSize: '28px'}}>
                         
                          
                            <AiOutlineLogin  onClick={this.rotate(image, key)} />

                            </div>
                           
                        </Col>
                      
                    </>
                    ))}
                
                </Row>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{ color: validation.price === false ? "red" : null }}
                  >
                    <Required />
                    Add Price
                  </label>
                  <Input
                    style={{ height: 41 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-phone"
                    onChange={(e) => this.onChange(e.target.value, "price")}
                    placeholder="Add price"
                    type="number"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup style={{ margin: 0 }}>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <Input
                      className="custom-control-input"
                      checked={approve}
                      id="approve"
                      onChange={(e) =>
                        this.onChange(e.target.checked, "approve")
                      }
                      type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor="approved">
                      Approval
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-link"
                    style={{
                      color: validation.video_link === false ? "red" : null,
                    }}
                  >
                    <Required />
                    Add Video Link
                  </label>
                  <Input
                    style={{ height: 41 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-link"
                    onChange={(e) =>
                      this.onChange(e.target.value, "video_link")
                    }
                    placeholder="Add Video Link"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-discount"
                    style={{
                      color: validation.discount === false ? "red" : null,
                    }}
                  >
                    <Required />
                    Add Product Discount
                  </label>
                  <Input
                    style={{ height: 35 }}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-discount"
                    onChange={(e) => this.onChange(e.target.value, "discount")}
                    placeholder="Add Discount"
                    type="number"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{
                      color: validation.description === false ? "red" : null,
                    }}
                  >
                    <Required />
                    Product Description
                  </label>
                 <SunEditor 
                  setOptions={{
                    height: 200,
                  buttonList: buttonList.complex // Or Array of button list, eg. [['font', 'align'], ['image']]
                  // Other option
                  }}
                  placeholder="Please type here..."
                  onChange={this.handleChange} />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" style={{ padding: "1rem" }}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={saving}
              onClick={toggle}
            >
              Close
            </Button>
            <Button
              color="success"
              type="button"
              disabled={saving}
              size="sm"
              onClick={this.onSave}
              style={{
                backgroundColor: "#EC3237",
                borderColor: "#EC3237",
                color: "#fff",
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddProduct;
