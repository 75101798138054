import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormText,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SunEditor, { buttonList } from 'suneditor-react';

import 'suneditor/dist/css/suneditor.min.css';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

import { getProduct, updateProduct } from "services/productService";
import { getUser } from "services/authService";
import SpinDiv from "components/SpinDiv.jsx";
import axios from "axios";
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { AiOutlineLogin } from "react-icons/ai";
const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const numberReg = /^([0]\d{10})$/;
class Product extends React.Component {
  constructor(props) {
    super(props);
    const { location: state } = props;
    this.state = {
      loading: false,
      edit: false,
      change: false,
      product: state && state.product ? state.product : null,
      validation: {},
      categories: [],
      single_images: [],
      slider_images: [],
      id: props.match.params.id,
      users: [],
      images: [],
      rotation: 0
    };
  }

  componentDidMount() {
    this.getProduct();
  }

  getProduct = () => {
    const { id } = this.state;
    this.setState({ loading: true });
    getProduct(id).then(
      res => {
        console.log(res);
        this.setState({
          loading: false,
          product: { ...res.product },
          initialProduct: { ...res.product },
          categories: res.categories,
          single_images: res.single_images,
          slider_images: res.single_images.map((size) => ({
            src: `${settings.IMG_URL}products/${size}`
          })),
          users: res.users
          //.map(p=>({label:p.name, value:p.id})),
        });
      },
      error => {
        this.setState({ loading: false });
      }
    );
  };

  toggleEdit = () => {
    const { initialProduct } = this.state;
    this.setState({ edit: !this.state.edit, product: { ...initialProduct } });
  };

  togglePassword = () => {
    this.setState({ change: !this.state.change });
  };

  onSave = async (e) => {
    e.preventDefault();
    const {
      productname,
      category_id,
      approve,
      description,
      video_link,
      price,
      discount,
      images,
      images_name,
      validation,
    } = this.state;
    if (!this.validateProductName() || !this.validatePrice() ||
      !this.validateDiscount()) {
      return;
    } else {
      this.saveProduct();
    }
  };
  saveProduct = () => {
    this.setState({ saving: true });
    let data = new FormData();
    if (this.state.images) {

      let files = this.state.images;
      for (let i = 0; i < files.length; i++) {
        let new_rotation = files[i].rotation == undefined ? 0 : files[i].rotation;
         data.set(`rotations[${i}]`, new_rotation )
      }
      for (let i = 0; i < files.length; i++) {
        data.append(`images[${i}]`, files[i])
      }

    }
    data.set("productname", this.state.product.productname);
    data.set("category_id", this.state.product.category_id);
    data.set("single_image", this.state.product.single_image);
    data.set("discount", this.state.product.discount);
    data.set("stock_update", this.state.product.stock_update);
    data.set("description", this.state.product.description);
    data.set("approve", this.state.product.approve);
    data.set("video_link", this.state.product.video_link);
    data.set("price", this.state.product.price);

    return axios
      .post(
        `${settings.API_URL}updateproduct/${this.state.product.id}`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      )
      .then((res) => {
        console.log(res.data);
        this.showToast("Product Updated");
        document.getElementById('myedit').style.display = 'none';
        document.getElementById('back').style.display = 'block';
        this.setState({ saving: false, edit: false });
        this.getProduct(this.state.product.id);
        //this.props.history.push(`/admin/products/${this.state.product.slug}`);
        //document.getElementById('saved').style.display = 'none';

      })
      .catch((err) => {
        console.log(err);
        this.setState({
          errorMessage: err,
          show: true,
        });
        this.setState({ saving: false });
      });
  };


  validateProductName = () => {
    let validate = true;
    let textContent = null;
    if (document.getElementById('productname').value.length == 0) {
      validate = false;
      textContent = 'Name is required';
    } else if (document.getElementById('productname').value.trim().length < 4) {
      validate = false;
      textContent = 'Product Name should be more than 3 characters';
    }
    document.getElementById('productname').style.color = validate ? null : 'red';
    document.getElementById('productname').style.borderColor = validate ? null : 'red';
    document.getElementById('name-error').textContent = validate ? null : textContent;
    return validate;
  }
  /*validateDescription=()=>{
    let validate = true;
    let textContent = null;
    if(document.getElementById('description').value.length==0){
      validate = false;
      textContent = 'Description is required';
    } else if (document.getElementById('description').value.trim().length<4){
      validate = false;
      textContent = 'Product Name should be more than 3 characters';
    }
    document.getElementById('description').style.color = validate?null:'red';
    document.getElementById('description').style.borderColor = validate?null:'red';
    document.getElementById('description-error').textContent = validate?null:textContent;
    return validate;
  }*/
  validatePrice = () => {
    let validate = true;
    let textContent = null;
    if (document.getElementById('price').value.length == 0) {
      validate = false;
      textContent = 'price is required';

    } else if (isNaN((document.getElementById('price').value))) {
      validate = false;
      textContent = 'price is invalid';
    }
    document.getElementById('price').style.color = validate ? null : 'red';
    document.getElementById('price').style.borderColor = validate ? null : 'red';
    document.getElementById('price-error').textContent = validate ? null : textContent;
    return validate;
  }
  validateDiscount = () => {
    let validate = true;
    let textContent = null;
    if (document.getElementById('discount').value.length == 0) {
      validate = false;
      textContent = 'Discount is required';
    } else if (isNaN(document.getElementById('price').value)) {
      validate = false;
      textContent = 'Discount is invalid';
    }
    document.getElementById('discount').style.color = validate ? null : 'red';
    document.getElementById('discount').style.borderColor = validate ? null : 'red';
    document.getElementById('discount-error').textContent = validate ? null : textContent;
    return validate;
  }
  onChange = (e, state) => {
    console.log(e);
    const { product } = this.state;

    this.setState({ product: { ...product, [state]: e } });
  };
  handleChange = content => {
    const { product } = this.state;
    this.setState({ product: { ...product, description: content } });
    //this.setState({summary:content });
    //Get Content Inside Editor
  };
  imageHandler = e => {
    this.setState({ images: [...this.state.images, ...e.target.files] });
  };

  showToast = msg => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  rotate = (image, index) => () => {
    const { images } = this.state;
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    image.rotation = newRotation;
    images.splice(index, 1, image);
    this.setState({
      rotation: newRotation,
      rotation_index: index,
    })
  }

  render() {

    const {
      product,
      loading,
      validation,
      edit,
      categories,
      single_images,
      slider_images,
      images_name,
      initialProduct,
      saving,
      change,
      users,
      rotation,
      rotation_index,
    } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minWidth: 170,
        height: 46,
        borderColor: "hsl(0,0%,95%)",
        boxShadow: "1px 1px 1px rgba(50, 50, 50, 0.1)"
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      }
    };

    return (
      <>
        <div
          className="header pb-8 pt-5 pt-md-6"
          style={{ backgroundColor: "#3E70D7" }}
        >
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/products"
                className="white-link"
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
              >
                <i className=" ni ni-bold-left" style={{ marginRight: 5 }} />
                <span>Back to Products</span>
              </Link>
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            {product && (
              <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                <Card className="card-profile shadow">
                  {product && (
                    <CardBody className="pt-0 pt-md-0">
                      <Row>
                        <div className="col">
                          <div className="card-profile-stats d-flex justify-content-center">
                            <div>
                              <span>Product Name: </span>
                              <span
                                style={{ display: "unset" }}
                                className="heading"
                              >
                                {product.productname}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Row>

                      <div className="text-center">
                        {product.product_id && (
                          <div className="h5 font-weight-300">
                            <i className="ni location_pin mr-2" />
                            Product id:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {product.product_id}
                            </span>
                          </div>
                        )}
                        {product.price && (
                          <div className="h5 font-weight-300">
                            <i className="ni location_pin mr-2" />
                            Price:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {product.price}
                            </span>
                          </div>
                        )}

                        {product.category_id && (
                          <div className="h5 mt-1 font-weight-300">
                            Category:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {product.category_id}
                            </span>
                          </div>
                        )}
                        {product.category_id && (
                          <div className="h5 mt-1 font-weight-300">
                            Front Image{" "}
                          </div>
                        )}
                        {product.single_image && (
                          <div className="h5 mt-1 font-weight-300">
                            <span style={{ fontWeight: "bold" }}>
                              <a target="_blank" href={`${settings.IMG_URL}` + 'products/' + product.single_image}>
                                <img src={`${settings.IMG_URL}` + 'products/' + product.single_image} alt="Forest" style={{
                                  width: '150px', border: '1px solid #ddd', borderRadius: '4px',
                                  padding: '5px',
                                  width: '150px'
                                }} />
                              </a>

                            </span>
                          </div>
                        )}
                        {product.video_link && (
                          <div className="h5 mt-1 font-weight-300">
                            Video Link:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {product.video_link}
                            </span>
                          </div>
                        )}


                        {product.discount && (
                          <div className="h5 mt-1 font-weight-300">
                            Discount:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {product.discount}
                            </span>
                          </div>
                        )}
                        <Row>
                          <Carousel images={slider_images} />
                        </Row>
                      </div>
                    </CardBody>
                  )}
                </Card>
              </Col>
            )}
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                {loading && <SpinDiv text={"Loading..."} />}
                {saving && <SpinDiv text={"Saving..."} />}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      {initialProduct && (
                        <h3 className="mb-0">
                          Owner: {initialProduct.user.name}
                        </h3>
                      )}
                    </Col>
                    {initialProduct &&
                      //(getUser() || getUser().id === initialProduct.user_id) ? (
                      <Col className="text-right" xs="4">
                        {product && (
                          <Button
                            id="myedit"
                            color={edit ? "secondary" : "success"}
                            onClick={this.toggleEdit}
                            size="sm"
                          >
                            {edit ? "Discard Changes" : "Edit"}
                          </Button>
                        )}
                        {
                          <Link to="/admin/products" id="back" style={{ display: "none" }}>
                            <Button color="success" size="sm">
                              Back
                            </Button>
                          </Link>
                        }
                      </Col>
                      //) : (

                      //)
                    }

                  </Row>
                </CardHeader>
                {product && (
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-2">
                        Product information
                      </h6>
                      <div>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-productname"
                                style={{
                                  color:
                                    validation.productname === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <p id="name-error" style={{ color: "red" }} class="error" />
                                <Required />
                                Product name
                              </label>
                              <Input
                                value={product.productname}
                                type="text"
                                id="productname"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "productname"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-category_id"
                                style={{
                                  color:
                                    validation.category_id === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Category
                              </label>
                              <Input
                                value={product.single_image !== null ? product.single_image : ""}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "category_id"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Category
                                </option>
                                {categories.map(p => (
                                  <option value={p} key={p}>
                                    {p}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-approve"
                                style={{
                                  color:
                                    validation.stock_update === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Stock Update
                              </label>
                              <Input
                                value={product.stock_update}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "stock_update"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Product Availability update
                                </option>
                                <option value="Available">Available</option>
                                <option value="Close">Close</option>


                              </Input>
                            </FormGroup>
                          </Col>

                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-single_image"

                              >
                                <Required />
                                Choose Front Image view
                              </label>
                              <Input
                                value={product.single_image}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "single_image"
                                  );
                                }}
                              >

                                <option disabled value="">
                                  Select Image Name
                                </option>
                                {single_images.map(p => (
                                  <option value={p} key={p}>
                                    {p}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-price"
                                style={{
                                  color:
                                    validation.price === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Price
                              </label>
                              <p id="price-error" style={{ color: "red" }} class="error" />
                              <Input
                                value={product.price}
                                id="price"
                                type="number"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(e.target.value, "price");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-images"

                              >
                                Upload Product Images
                              </label>
                              <input
                                style={{ height: 41 }}
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-images"
                                onChange={this.imageHandler}

                                type="file"
                                multiple
                              />
                              <FormText color="muted">
                                you can only upload jpg,jpeg and png type
                              </FormText>
                            </FormGroup>
                            <Row>
                              {this.state.images.map((image, key) => (


                                <>

                                  <Col md={4}>
                                    <img style={{ transform: key == rotation_index ? `rotate(${rotation}deg` : 'rotate(0)deg', opacity: 0.7 }} src={URL.createObjectURL(image)} width="100" />
                                    <div style={{ position: 'absolute', top: '50%', width: '100%', textAlign: 'center', fontSize: '28px' }}>


                                      <AiOutlineLogin onClick={this.rotate(image, key)} />

                                    </div>

                                  </Col>

                                </>
                              ))}
                            </Row>
                          </Col>

                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-discount"
                                style={{
                                  color:
                                    validation.discount === false
                                      ? "red"
                                      : null
                                }}

                              >

                                <Required />
                                Discount
                              </label>
                              <p id="discount-error" style={{ color: "red" }} class="error" />
                              <Input
                                value={product.discount}
                                id="discount"
                                type="number"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "discount"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-approve"
                                style={{
                                  color:
                                    validation.approve === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Approval
                              </label>
                              <Input
                                value={product.approve}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "approve"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Approve
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>

                              </Input>
                            </FormGroup>
                          </Col>

                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                                style={{
                                  color:
                                    validation.video_link === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Video Link
                              </label>
                              <Input
                                value={product.video_link}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "video_link"
                                  );
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-description"
                              >
                                Description:
                                <Required />
                              </label>
                              <p id="description-error" style={{ color: "red" }} class="error" />
                              <SunEditor
                                setOptions={{
                                  height: 200,
                                  buttonList: buttonList.complex // Or Array of button list, eg. [['font', 'align'], ['image']]
                                  // Other option
                                }}
                                disable={!edit}
                                setContents={product.description}
                                onChange={this.handleChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr style={{ marginBottom: 20, marginTop: 10 }} />
                      {edit && (
                        <div>
                          <Button
                            color="success"
                            id="saved"
                            type="button"
                            disabled={saving}
                            onClick={this.onSave}
                            style={{
                              backgroundColor: "#EC3237",
                              borderColor: "#EC3237",
                              color: "#fff"
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Product;
