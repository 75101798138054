/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Switch,Route} from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import routes from "routes.js";
import {SecureRoute} from '../services/SecureRoute'
import Index from "../views/Index";
import Categories from "views/settings/categories/Index.jsx";
import BlogCategories from "views/settings/blogcategories/Index.jsx";
import Sliders from "views/settings/slider/Index.jsx";
import Product from "views/products/Product.jsx";
import Blog from "views/blogs/Blog.jsx";
import Download from "views/requests/Download.jsx";
class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <SecureRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          /*logo={{
            innerLink: "/admin/home",
            imgSrc: require("assets/img/brand/logo.png"),
            imgAlt: "..."
          }}*/
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
           <SecureRoute exact path="/admin" component={Index}/>
           <Route exact path="/admin/products/:id" render={props => <Product {...props} />} />
           <Route exact path="/admin/blogs/:id" render={props => <Blog {...props} />} />
           <Route exact path="/admin/requests/:id" render={props => <Download {...props} />} />
           <Route exact path="/admin/settings/categories" render={props => <Categories {...props} />} />
           <Route exact path="/admin/settings/blogcategories" render={props => <BlogCategories {...props} />} />
           <Route exact path="/admin/settings/slides" render={props => <Sliders {...props} />} />
            {this.getRoutes(routes)}
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admin;
