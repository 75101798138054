import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input,
  Badge
} from "reactstrap";
import { toast } from 'react-toastify';
import "views/index.css";
import { getBlogs }  from 'services/blogService';
import {getUser} from 'services/authService'
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";
import AddBlog from "./AddBlog.jsx";
import DeleteBlog from "./DeleteBlog";

class Blogs extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
    blogs:[],
  	page: 1,
  	rows: 10,
  	total: 0,
    search: '',
    categories:[],
  };

  componentDidMount() {
    this.getBlogs();
  }

  componentWillMount() {
  }

  

  getBlogs = () => {
    const {category,user, page, rows, search} = this.state;
    this.setState({loading: true});
    getBlogs({user, page,category, rows, search})
      .then((res) => {console.log(res)
        this.setState({
          blogs: res.blogs.data,
          tags:res.tags,
          categories:res.categories,
          page: res.blogs.current_page,
          total: res.blogs.total,
          loading: false,
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  toggleModal = () => {
    toast.dismiss()
    this.setState({addBlog: !this.state.addBlog});
  }
  toggleDelete = (deleteBlog) => {
    this.setState({ deleteBlog });
  };


  onChange = (e, state) => {
    this.setState({[state]: e})
  }
 
  
  onPage = async (page) => {
    const {search} = this.state;
    await this.setState({page});
    if(search===''){
      await this.getBlogs();
    } else {
      await this.search();
    }
  }

  onSearch = () => {

  }

  

  

  
  showToast = msg => {
    toast.error(<div style={{padding:25}}>{msg}</div>);
  }


  render() {
    const {blogs,categories,tags,studylevels,users, addBlog, deleteBlog,total, page, rows, search, loading} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#3E70D7'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        {addBlog&&
          <AddBlog
            saved={this.getBlogs}
            toggle={this.toggleModal}
            addBlog={addBlog}
            categories={categories}
            tags={tags}
          />}
          {deleteBlog && (
          <DeleteBlog
            saved={this.getBlogs}
            deleteBlog={deleteBlog}
            toggle={() => this.setState({ deleteBlog: null })}
          />
        )}
          
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap'}}>
                    <div>
                      <h3 className="mb-0">Blogs <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    <div className="mobile-flex">
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getBlogs();}}} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getBlogs}>
                        <i className="fa fa-search" />
                      </Button>
                      {<Button
                        onClick={() => this.toggleModal()}
                        size="sm"
                        style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}
                      >
                        Create Blog Post
                      </Button>}
                    </div>
                  </Row>
                </CardHeader>
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Author</th>
                      <th scope="col">Title</th>
                      <th scope="col">Category</th>
                      <th scope="col">Views</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blogs.map((blog, key) => {
                      return (
                        <tr >
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/blogs/'+blog.id)
                        }}>{blog.user.name}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/blogs/'+blog.id)
                        }}>{blog.title}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/blogs/'+blog.id)
                        }}>{blog.blogcategory.name}</td>
                          <td key={key} className="hover-list" to="/" onClick={() => {//console.log('111')
                        this.props.history.push('/admin/blogs/'+blog.id)
                        }}>{blog.views  >= 1 ? blog.views : 0}</td>
                          
                          <td>
                            <Button
                              color="danger"
                              onClick={() => this.toggleDelete(blog)}
                              size="sm"
                            >
                              Delete
                            </Button>
                          </td>
                          
                        </tr>
                        
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&blogs.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Blog found
                </div>}
               
                {blogs.length>0&&<CardFooter className={`py-4 ${loading?'load-opacity':''}`}>
                  <CustomPagination page={page} total={total} rows={rows} onPage={this.onPage} />
                </CardFooter>}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Blogs;
