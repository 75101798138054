import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const categoryService = {
    addSlider,
    getSliders,
   
};

export function getSliders(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}slides`, requestOptions).then(authService.handleResponse);
}

export function addSlider(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addslider`, requestOptions).then(authService.handleResponse);
}

