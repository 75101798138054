import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormText,
  UncontrolledTooltip
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { getBlog, updateBlog } from "services/blogService";
import SpinDiv from "components/SpinDiv.jsx";
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { getUser, authService } from "../../services/authService";

class Blog extends React.Component {
  constructor(props) {
    super(props);
    const { location: state } = props;
    this.state = {
      loading: false,
      edit: false,
      change: false,

      blog: state && state.blog ? state.blog : null,
      validation: {},
      categories: [],
      id: props.match.params.id,
      selectOptions: [],
      users: []
    };
  }

  componentDidMount() {
    this.getBlog();
  }

  getBlog = () => {
    const { id } = this.state;
    this.setState({ loading: true });
    getBlog(id).then(
      res => {
        console.log(res.blog.blogcategory);
        this.setState({
          loading: false,
          blog: { ...res.blog },
          initialBlog: { ...res.blog },
          categories: res.categories,
          users: res.users
          //.map(p=>({label:p.name, value:p.id})),
        });
      },
      error => {
        this.setState({ loading: false });
      }
    );
  };

  toggleEdit = () => {
    const { initialBlog } = this.state;
    this.setState({ edit: !this.state.edit, blog: { ...initialBlog } });
  };

  togglePassword = () => {
    this.setState({ change: !this.state.change });
  };

  onSave = async e => {
    e.preventDefault();
    await toast.dismiss();
    const { blog, validation } = this.state;
    const { title, category_id, body, visible, id } = blog;
    await this.setState({
      validation: {
        ...validation,
        title: title !== "",
        category_id: category_id !== "",
        body: body !== ""
      }
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveBlog();
    } else {
      const errors = Object.keys(this.state.validation).filter(id => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(
        () =>
          toast.error(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold" }}>Errors:</p>
              {errors.map(v => (
                <p key={v} style={{ margin: 0, fontSize: 14 }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          ),
        250
      );
    }
  };

  saveBlog = () => {
    this.setState({ saving: true });
    const { title, category_id, tag_id, body, image } = this.state;
    //if (this.state.image) {
    let data = new FormData();
    //data.append("image", this.state.image);
    data.append("image", this.state.blog.image);
    data.set("title", this.state.blog.title);
    data.set("category_id", this.state.blog.category_id);
    data.set("body", this.state.blog.body);
    return axios
      .post(
        `${settings.API_URL}updateblog/${this.state.blog.id}`,
        data,
        {
          headers: authHeader()
        },
        authService.handleResponse
      )
      .then(res => {
        console.log(res.data);
        this.setState({ saving: false, edit: false });
        this.getBlog(this.state.blog.id);

        this.showToast("Blog Updated");
      })
      .catch(err => {
        console.log(err);
        this.setState({
          errorMessage: err,
          show: true
        });
        if (this.state.errorMessage) {
          console.log(this.state.errorMessage);
          //this.showToast("Invalid title / Image Type or size");
        }
        this.setState({ saving: false });
      });
    //}
  };

  validationRules = field => {
    if (field === "title") {
      return "title is required";
    } else if (field === "category_id") {
      return "Category is required";
    } else if (field === "body") {
      return "Blog post is required";
    }
  };

  onChange = (e, state) => {
    //console.log(e);
    const { blog } = this.state;

    this.setState({ blog: { ...blog, [state]: e } });
  };

  handleChange = content => {
    const { blog } = this.state;
    this.setState({ blog: { ...blog, body: content } });
    //this.setState({summary:content });
    //Get Content Inside Editor
  };

  showToast = msg => {
    toast.error(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const {
      blog,
      loading,
      validation,
      edit,
      categories,
      initialBlog,
      saving,
      change,
      users
    } = this.state;
    const Required = () => <span style={{ color: "red" }}>*</span>;
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minWidth: 170,
        height: 46,
        borderColor: "hsl(0,0%,95%)",
        boxShadow: "1px 1px 1px rgba(50, 50, 50, 0.1)"
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      }
    };

    return (
      <>
        <div
          className="header pb-8 pt-5 pt-md-6"
          style={{ backgroundColor: "#3E70D7" }}
        >
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/blog"
                className="white-link"
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
              >
                <i className=" ni ni-bold-left" style={{ marginRight: 5 }} />
                <span>Back to Blogs</span>
              </Link>
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                {loading && <SpinDiv text={"Loading..."} />}
                {saving && <SpinDiv text={"Saving..."} />}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      {initialBlog && (
                        <h3 className="mb-0">Owner: {initialBlog.user.name}</h3>
                      )}
                    </Col>
                    {
                      <Col className="text-right" xs="4">
                        {blog && (
                          <Button
                            color={edit ? "secondary" : "success"}
                            onClick={this.toggleEdit}
                            size="sm"
                          >
                            {edit ? "Discard Changes" : "Edit"}
                          </Button>
                        )}
                      </Col>
                   }
                  </Row>
                </CardHeader>

                {blog && (
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-2">
                        Blog information
                      </h6>
                      <div>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                                style={{
                                  color:
                                    validation.title === false ? "red" : null
                                }}
                              >
                                <Required />
                                Title
                              </label>
                              <Input
                                value={blog.title}
                                type="text"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(e.target.value, "title");
                                }}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-category_id"
                                style={{
                                  color:
                                    validation.category_id === false
                                      ? "red"
                                      : null
                                }}
                              >
                                <Required />
                                Category
                              </label>
                              <Input
                                value={blog.category_id}
                                type="select"
                                bsSize="sm"
                                style={{ marginRight: 10, width: "100%" }}
                                disabled={!edit}
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.value,
                                    "category_id"
                                  );
                                }}
                              >
                                <option disabled value="">
                                  Select Category
                                </option>
                                {categories.map(p => (
                                  <option value={p.name} key={p.id}>
                                    {p.name}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-image"
                                style={{
                                  color:
                                    validation.image === false ? "red" : null
                                }}
                              >
                                Upload Image
                              </label>
                              <Input
                                style={{ height: 41 }}
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-image"
                                onChange={async e => {
                                  await this.onChange(
                                    e.target.files[0],
                                    "image"
                                  );
                                }}
                                type="file"
                              />
                              <FormText color="muted">
                                you can only upload Image
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Col lg="6"></Col>
                        </Row>

                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-body"
                              >
                                Body:
                                <Required />
                              </label>
                              <SunEditor
                                setOptions={{
                                  height: 200,
                                  buttonList: buttonList.complex // Or Array of button list, eg. [['font', 'align'], ['image']]
                                  // Other option
                                }}
                                disable={!edit}
                                setContents={blog.body}
                                onChange={this.handleChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr style={{ marginBottom: 20, marginTop: 10 }} />
                      {edit && (
                        <div>
                          <Button
                            color="success"
                            type="button"
                            disabled={saving}
                            onClick={this.onSave}
                            style={{
                              backgroundColor: "#EC3237",
                              borderColor: "#EC3237",
                              color: "#fff"
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Blog;
