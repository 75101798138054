import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';
export function getRequests(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}requestss`, requestOptions).then(authService.handleResponse);
}

export function getRequest(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}request/${id}`, requestOptions).then(authService.handleResponse);
}
