import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const categoryService = {
    addBlog,
    getBlogs,
    updateBlog,
    deleteBlog
};

export function getBlogs(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}blogs`, requestOptions).then(authService.handleResponse);
}

export function addBlog(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addblog`, requestOptions).then(authService.handleResponse);
}

export function getBlog(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}blog/${id}`, requestOptions).then(authService.handleResponse);
}

export function updateBlog(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateblog/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteBlog(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteblog/${id}`, requestOptions).then(authService.handleResponse);
}
