import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import { toast } from 'react-toastify';
import "views/index.css";
import { addProduct, getProducts }  from 'services/productService';
import DeleteProduct from "./DeleteProduct";
import {getUser} from 'services/authService'
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";
import AddProduct from "./AddProduct.jsx";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';

class Products extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
  	page: 1,
  	rows: 10,
  	total: 0,
    search: '',
    users: [],
    products: [],
    categories:[],
  };

  componentDidMount() {
    this.getProducts();
  }

  componentWillMount() {
  }

  saveProduct = (e) => {
    e.preventDefault();
    const {name, phone, email, address} = this.state;
    addProduct({name, phone, email, address})
      .then(res => {
          this.setState({message : 'Product added successfully.'});
          this.props.history.push('/products');
      },
    	error => {
    		console.log(error)
    	});
  }

  getProducts = () => {
    const {category,user, page, rows, search} = this.state;
    this.setState({loading: true});
    getProducts({user, page,category, rows, search})
      .then((res) => {console.log(res)
        this.setState({
          products: res.products.data,
          users: res.users,
          categories:res.categories,
          page: res.products.current_page,
          total: res.products.total,
          loading: false,
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  toggleModal = () => {
    toast.dismiss()
    this.setState({addProduct: !this.state.addProduct});
  }
  toggleDelete = (deleteProduct) => {
    this.setState({ deleteProduct });
  };


  onChange = (e, state) => {
    this.setState({[state]: e})
  }
  
  onPage = async (page,rows) => {
    const {search} = this.state;
    await this.setState({page,rows});
    if(search===''){
      await this.getProducts();
    } else {
      await this.search();
    }
  }

  onSearch = () => {

  }

  
  
  showToast = msg => {
    toast.error(<div style={{padding:25}}>{msg}</div>);
  }


  render() {
    const {products,categories,users, addProduct,deleteProduct, total, page, rows, search, loading} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#3E70D7'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        {addProduct&&
          <AddProduct
            saved={this.getProducts}
            toggle={this.toggleModal}
            addProduct={addProduct}
            users={users}
            categories={categories}
          />}
           {deleteProduct && (
          <DeleteProduct
            saved={this.getProducts}
            deleteProduct={deleteProduct}
            toggle={() => this.setState({ deleteProduct: null })}
          />
        )}
         
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap'}}>
                    <div>
                      <h3 className="mb-0">Products <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    <div className="mobile-flex">
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getProducts();}}} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getProducts}>
                        <i className="fa fa-search" />
                      </Button>
                      {<Button
                        onClick={() => this.toggleModal()}
                        size="sm"
                        style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}
                      >
                        Create Product
                      </Button>}
                    </div>
                  </Row>
                </CardHeader>
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Product Name</th>
                      <th scope="col">Category</th>
                      <th scope="col">Price</th>
                      <th scope="col">Product Id</th>
                      <th scope="col">Availability</th>
                       <th scope="col">approve</th>
                      <th scope="col">Date and time</th>
                      
                     
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product, key) => {
                      return (
                        <tr key={key} className="hover-list" to="/"  >
                          
                          <td onClick={() => {//console.log('111')
                        this.props.history.push('/admin/products/'+product.id)}}>{product.productname}</td>
                          <td onClick={() => {//console.log('111')
                        this.props.history.push('/admin/products/'+product.id)}}>{product.category_id}</td>
                          <td onClick={() => {//console.log('111')
                        this.props.history.push('/admin/products/'+product.id)}} >{product.price }</td>
                          <td onClick={() => {//console.log('111')
                        this.props.history.push('/admin/products/'+product.id)}} >{product.product_id}</td>
                        <td onClick={() => {//console.log('111')
                        this.props.history.push('/admin/products/'+product.id)}}>{product.stock_update}</td>
                           <td onClick={() => {//console.log('111')
                        this.props.history.push('/admin/products/'+product.id)}}>{product.approve}</td>
                        
                          <td onClick={() => {//console.log('111')
                        this.props.history.push('/admin/products/'+product.id)}}>{product.created_at }</td>   
                          <Button
                              color="danger"
                              onClick={() => this.toggleDelete(product)}
                              size="sm"
                            >
                              Delete
                            </Button> 
  
                        </tr>
                        
                        
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&products.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No product found
                </div>}
               
               <Row style={{marginTop:10}}>
               <Col md={12}>
                 {products.length>0&&<Pagination
                  total={total}
                  showTotal={total => `Total ${total} items`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                  showSizeChanger
                  showQuickJumper
                />}
               </Col>
               </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Products;
